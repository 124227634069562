<template>
  <rest
    :remote="remote"
    :table-columns="tableColumns"
    :formInitialize="formInitialize"
    :form-rules="formRules"
    :exportable="true"
    :create-able="true" :update-able="true" :delete-able="true"
    ref="rest"
  >
    <template slot="formExt2">
      <url-generate style="margin-left: 10px;" @complete="handleShortUrlGenerateComplete"></url-generate>
    </template>
    <template slot="tableColumn-prefixShortUrl" slot-scope="{row}">
      <ext-text :content="getPrefixShortUrlWithHost(row.prefix, row.shortUrl)"/>
    </template>
  </rest>
</template>

<script>
import Rest from '@/components/Spring/Rest.vue'
import api from '@/api'
import ExtText from '@/components/ExtText/index.vue'
import UrlGenerate from '@/views/ShortUrl/UrlGenerate.vue'
export default {
  name: 'urlMaps',
  components: { UrlGenerate, ExtText, Rest },

  data () {
    return {
      remote: {
        path: 'short-url',
        resource: this.$options.name,
        realtime: true
      },
      formInitialize: {
        type: 0
      },
      formRules: {
        shortUrl: [{ required: true, message: '请填写 短链接' }],
        longUrl: [{ required: true, message: '请填写 长链接' }]
      },
      blankPrefixData: {},
      prefixMap: new Map()
    }
  },
  computed: {
    tableColumns () {
      return [
        { label: 'ID', prop: 'id', hiddenForm: true },
        { label: '前缀', prop: 'prefix' },
        { label: '短链', prop: 'shortUrl' },
        { label: '短链接', prop: 'prefixShortUrl', hiddenForm: true, type: 'slot' },
        { label: '长链接', prop: 'longUrl' },
        { label: 'iOS链接', prop: 'iosUrl' },
        { label: '安卓链接', prop: 'androidUrl' },
        {
          label: '设备路由开关',
          prop: 'deviceRouteOpen',
          type: 'select',
          options: [
            { value: 0, label: '关闭' },
            { value: 1, label: '开启' }
          ]
        },
        {
          label: '链接类型',
          prop: 'type',
          type: 'select',
          options: [
            { value: 0, label: '永久链接' },
            { value: 1, label: '限时链接' }
          ],
          change (form) {
            if (form.type === 0) {
              form.expireTime = ''
            }
          }
        },
        {
          label: '过期时间',
          prop: 'expireTime',
          type: 'datetime',
          rules (form) {
            return [{
              validator (rule, value, callback) {
                if (form.type === 1 && !form.expireTime) {
                  return callback(new Error('限时链接必须设置过期时间'))
                }
                callback()
              },
              trigger: 'blur'
            }]
          }
        }
        // { label: '创建时间', prop: 'createdAt', type: 'datetime', hiddenForm: true },
        // { label: '修改时间', prop: 'updatedAt', type: 'datetime', hiddenForm: true }
      ]
    }
  },
  created () {
    this.fetchPrefixesData()
  },
  methods: {
    fetchPrefixesData () {
      api.get('/short-url/admin/url/blank/prefix/data').then((res) => {
        this.blankPrefixData = res
      })
      api.get('/short-url/admin/url/prefixes').then(res => {
        const map = new Map()
        for (const p of res) {
          map.set(p.prefix, p)
        }
        this.prefixMap = map
      })
    },
    getPrefixShortUrl (prefix, shortUrl) {
      if (!prefix) {
        return `/${shortUrl}`
      }
      return `/${prefix}/${shortUrl}`
    },
    getPrefixHost (prefix) {
      if (!prefix) {
        return this.blankPrefixData.host
      }
      const p = this.prefixMap.get(prefix) || {}
      return p.host
    },
    getPrefixShortUrlWithHost (prefix, shortUrl) {
      const host = this.getPrefixHost(prefix) || ''
      const prefixShortUrl = this.getPrefixShortUrl(prefix, shortUrl)
      return `${host}${prefixShortUrl}`
    },
    handleShortUrlGenerateComplete () {
      this.$refs.rest.fetchData()
    }
  }
}
</script>

<style scoped>
</style>
