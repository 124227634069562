<template>
  <section>
    <div slot="header" class="box">
      <el-form ref="searchForm" :inline="true" size="small">
        <template v-if="search.pathname">
          <el-form-item v-for="(value, key) in search.params" :key="key" :label="searchFormTemplate[key] ? searchFormTemplate[key].label : key">
            <template v-if="searchFormTemplate[key] && searchFormTemplate[key].type === 'select'">
              <el-select v-model="search.params[key]" clearable placeholder="请选择" @change="handleCurrentChange(1)">
                <el-option v-for="item in searchFormTemplate[key].options" :key="item.value" :label="item.value + ' - ' + item.label" :value="item.value"/>
              </el-select>
            </template>
            <template v-else>
              <el-input v-model="search.params[key]" @input="change()" clearable/>
            </template>
          </el-form-item>
          <slot name="formExt1"></slot>
          <br>
        </template>
      </el-form>
        <el-button type="primary" size="small" icon="el-icon-plus" :disabled="!canCreate" @click="dialogFormAdd"/>
        <template v-if="exportable">
          <el-popconfirm style="margin-left: 10px;" icon="el-icon-warning" title="请选择如何导出？" confirm-button-text="导出本页" cancel-button-text="导出全部" cancel-button-type="info" @confirm="handleDownload(true)" @cancel="handleDownload(false)">
            <el-button slot="reference" :loading="exportExcelLoading" type="warning" size="small">导出数据</el-button>
          </el-popconfirm>
          <el-button v-if="importable" type="info" size="small" style="margin-left: 10px;" @click="importVisible = true;">导入数据</el-button>
        </template>
        <template v-if="canCreate || canUpdate || canDelete">
          <template v-if="!remote.realtime">
            <el-popconfirm style="margin-left: 10px;" icon="el-icon-warning" title="你确定要让服务端立即生效么？" @confirm="reload">
              <el-button slot="reference" type="danger" size="small" icon="el-icon-warning">重载</el-button>
            </el-popconfirm>
            <span v-if="needReload" style="margin-left: 10px; color: #F56C6C;">👈 发现你修改了数据，点击重载才会生效！</span>
          </template>
          <template v-else>
            <span v-if="!remote.realtime" style="margin-left: 10px; color: #F56C6C;">👇 小心！该页面实时生效！</span>
          </template>
        </template>
        <slot name="formExt2"></slot>
        <div v-if="response.page" style="float: right; padding: 3px 0">
          <el-pagination :current-page="currentPage" background layout="total, sizes, prev, pager, next"
                         :page-sizes="[10, 20, 50, 100, 200, 1000]"
                         :page-size="response.page.size"
                         :total="response.page.totalElements"
                         :page-count="response.page.totalPages"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
          />
        </div>
    </div>
    <div class="box">
      <el-table v-loading="tableLoading" :data="tableData" stripe border style="margin-top: 20px"
        :header-cell-class-name="handleHeaderCellClassName" tooltip-effect="“dark”" @sort-change="handleSortChange">
        <el-table-column v-for="(item, i) in tableColumnsForTable" :key="i" :label="item.label" :prop="item.prop" :min-width="item.minWidth" :sortable="item.unSortable ? false : 'custom'">
          <template slot="header">
            <span>{{ item.label }}<sup style="color:#FF6347">
              {{ (v = sortParams.findIndex((x) => x.prop === item.prop)) >= 0 ? v + 1: '' }}
            </sup></span>
          </template>
          <template v-slot="scope">
            <el-popover v-if="utils.isTextTypeTooLong(item.type, scope.row[item.prop])" placement="top-start" trigger="click">
              <div>
                {{ scope.row[item.prop] }}
                <el-button type="success" size="mini" circle plain icon="el-icon-copy-document" @click="handleValueCopy(scope.row[item.prop])"></el-button>
              </div>
<!--              <el-button slot="reference" type="text" size="small">{{ utils.tableFormatter(item, scope.row[item.prop], scope.row) }}</el-button>-->
              <span slot="reference" style="color: #66b1ff; cursor: pointer; font-size: 12px;">{{ utils.tableFormatter(item, scope.row[item.prop], scope.row) }}</span>
            </el-popover>
            <template v-else-if="item.type === 'slot'">
              <slot :name="`tableColumn-${item.prop}`" :item="item" :row="scope.row"></slot>
            </template>
            <span v-else v-html="utils.tableFormatter(item, scope.row[item.prop], scope.row)" />
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template v-slot="scope">
            <el-button type="primary" icon="el-icon-edit" size="small" circle :disabled="!canUpdate" @click="dialogFormEdit(scope.row, scope.$index)"/>
            <el-button v-if="remote && remote.operateUrlReplace" type="info" icon="el-icon-refresh" size="small" circle @click="rowOperate(scope.row, scope.$index)"/>
            <el-button v-if="listeners && listeners.extra" type="info" icon="el-icon-s-operation" size="small" circle @click="clickExtra(scope.row)"/>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog :title="tableDataIndex < 0 ? '添加' : '编辑'" :visible.sync="dialogVisible" center destroy-on-close :fullscreen="isDialogFullscreen" :width="dialogWidth" :top="dialogTop">
      <el-form ref="ruleForm" :model="formModel" :rules="formRules" label-position="right" size="small" label-width="auto">
        <el-form-item v-for="(item, i) in tableColumnsForForm" :key="i" :label="item.label" :prop="item.prop" :rules="item.rules ? item.rules(formModel) : null ">
          <span slot="label">
            {{ item.label }}
            <el-tooltip v-if="item.labelHelp" :content="item.labelHelp">
              <i class="el-icon-question" />
            </el-tooltip>
          </span>
          <template v-if="item.type === 'switch'">
<!--            <el-switch v-model="formModel[item.prop]" :disabled="item.disabled && tableDataIndex >= 0"/>-->
            <el-switch v-model="formModel[item.prop]" active-color="#13ce66" inactive-color="#ff4949" :active-value="item.activeValue" :inactive-value="item.inactiveValue"/>
          </template>
          <template v-else-if="item.type === 'color'">
            <el-color-picker v-model="formModel[item.prop]" />
          </template>
          <template v-else-if="item.type === 'select'">
            <el-select v-model="formModel[item.prop]" :multiple="item.multiple" :disabled="item.disabled && tableDataIndex >= 0" placeholder="请选择" clearable
              :allow-create="item.allowCreate" :filterable="item.filterable || item.allowCreate" style="width: 100%;" @change="item.change ? item.change(formModel) : ''">
              <el-option v-for="option in item.options" :key="option.value" :label="option.value + ' - ' + option.label" :value="option.value" :disabled="option.disabled"/>
            </el-select>
          </template>
          <template v-else-if="item.type === 'radio'">
            <el-radio-group v-model="formModel[item.prop]">
              <el-radio v-for="option in item.options" :key="option.value" :label="option.value">{{option.label}}</el-radio>
            </el-radio-group>
          </template>
          <template v-else-if="item.type === 'number'">
            <el-input-number v-model="formModel[item.prop]" :min="item.min || -Infinity" :max="item.max || Infinity" :step="item.step || 1" :label="item.label"
              with="100%" :disabled="item.disabled && tableDataIndex >= 0" @change="item.change ? item.change(formModel) : null">
            </el-input-number>
          </template>
          <template v-else-if="item.type === 'date'">
            <el-date-picker v-model="formModel[item.prop]" type="date" :placeholder="item.placeholder || item.label"></el-date-picker>
          </template>
          <template v-else-if="item.type === 'datetime'">
            <el-date-picker v-model="formModel[item.prop]" type="datetime" :placeholder="item.placeholder || item.label"></el-date-picker>
          </template>
          <template v-else-if="item.type === 'argsKvl'">
            <args-kvl :form="formModel" :prop="item.prop" />
          </template>
          <template v-else-if="item.type === 'productTemplate'">
            <product-template :form="formModel" :prop="item.prop" />
          </template>
          <template v-else-if="item.type === 'productArgs'">
            <product-args v-if="dialogVisible" :form="formModel" :prop="item.prop" />
          </template>
          <!-- <template v-else-if="item.type === 'tinymce'">
            <tinymce v-model="formModel[item.prop]" :height="300" />
          </template> -->
          <template v-else>
            <template v-if="item.formType === 'slot'">
              <slot :name="`formItem-${item.prop}`" v-bind:item="item" v-bind:form="formModel"></slot>
            </template>
            <el-input v-else-if="!(formModel[item.prop] instanceof Array)" v-model="formModel[item.prop]" :placeholder="item.placeholder || item.label"
              :show-password="item.showPassword" :type="item.type" :autosize="item.autosize" :disabled="item.disabled && tableDataIndex >= 0"
              @change="item.change ? item.change(formModel) : null">
            </el-input>
          </template>
          <template v-if="item.plusType === 'image'">
            <el-image v-if="typeof formModel[item.prop] === 'string'" :src="formModel[item.prop]"/>
            <el-image v-else style="width: 100px; height: 100px" fit="contain" :src="require('@/assets/img/view-more.jpg')" :preview-src-list="formModel[item.prop]"/>
          </template>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <slot name="formItem-actions" v-bind:form="formModel" :dataIndex="tableDataIndex" :updateFn="customUpdate">
          <el-button v-if="tableDataIndex < 0" type="default" @click="dialogFormReset">重 置</el-button>
          <el-button v-if="tableDataIndex >= 0" type="danger" :disabled="!canDelete" @click="dialogFormSubmit(true)">删 除</el-button>
          <el-button type="primary" @click="dialogFormSubmit(false)">确 定</el-button>
        </slot>
      </div>
    </el-dialog>

    <el-dialog title="导入数据" :visible.sync="importVisible" center estroy-on-close>
      <el-upload ref="import" drag :limit="1" accept=".xlsx" action="" style="text-align: center;" :auto-upload="false">
        <i class="el-icon-upload" />
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div slot="tip" class="el-upload__tip">只能上传通过【导出表格】得到的表格，不要修改前2行，不要插入其他列！</div>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-popconfirm title="你真的真的确定么？" @confirm="handleImportExcel(true)">
          <el-button slot="reference" type="danger" :disabled="true">覆盖</el-button>
        </el-popconfirm>
        <el-button type="primary" style="margin-left: 50px;" :loading="importExcelLoading" @click="handleImportExcel(false)">更新</el-button>
        <el-divider />
        <el-descriptions :column="1">
          <el-descriptions-item label="更新">插入新数据或更新旧数据，不会影响不在表格内的数据</el-descriptions-item>
          <el-descriptions-item label="覆盖">清空所有旧数据，然后导入表格的数据（非常危险）</el-descriptions-item>
        </el-descriptions>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import * as XLSX from 'xlsx'
import api from '@/api'
import utils from '@/common/utils'
import ArgsKvl from '../ArgsKvl/index.vue'
import ProductTemplate from '@/components/ProductTemplate/index.vue'
import ProductArgs from '@/components/ProductArgs/index.vue'
// import Tinymce from '@/components/Tinymce'

export default {
  name: 'Rest',
  components: { ArgsKvl, ProductTemplate, ProductArgs },
  props: {
    remote: { type: Object, required: true },
    tableColumns: { type: Array, required: true },
    formInitialize: { type: Object, required: true },
    formRules: { type: Object, required: true },
    listeners: { type: Object, default: null },
    tableFormatter: { type: Function, default: null },
    // 导出表格相关
    // 该字段和导出组件的显示隐藏有关
    exportable: {
      type: Boolean,
      default: false
    },
    importable: {
      type: Boolean,
      default: false
    },
    createAble: {
      type: Boolean,
      default: false
    },
    updateAble: {
      type: Boolean,
      default: false
    },
    deleteAble: {
      type: Boolean,
      default: false
    },
    dialogFullscreen: {
      type: Boolean,
      default: false
    },
    dialogWidth: {
      type: String,
      default: '50%'
    },
    dialogTop: {
      type: String,
      default: '6vh'
    }
  },

  data () {
    return {
      utils,
      response: { _embedded: {}, page: {}, _links: {} },
      search: { pathname: '', params: { } },
      needReload: false,
      dialogVisible: false,
      formModel: { ...this.formInitialize },
      tableDataIndex: -1,
      tableLoading: true,
      currentPage: 1, // 前端：页码从1开始, 后端：页码从0开始
      sortParams: [],
      // 表格相关
      exportExcelLoading: false,
      importExcelLoading: false,
      importVisible: false,
      // 操作限制
      canCreate: false,
      canUpdate: false,
      canDelete: false
    }
  },

  computed: {
    resource () {
      return utils.firstLowerCase(this.remote.resource)
    },
    resources () {
      return utils.pluralize(this.resource)
    },
    searchFormTemplate () {
      return this.tableColumns.reduce((pre, cur) => {
        return { ...pre, [cur.prop]: cur }
      }, {})
    },
    tableData () {
      if (this.listeners?.handleFetchData) {
        return this.listeners?.handleFetchData(this.response._embedded[this.resources])
      }
      return this.response._embedded[this.resources]
    },
    createPath () {
      return `/${this.remote.path}/admin/api/${this.resources}`
    },
    changePath () {
      return new URL(this.tableData[this.tableDataIndex]._links.self.href).pathname
    },
    profilePath () {
      return `/${this.remote.path}/admin/api/profile/${this.resources}`
    },
    reloadPath () {
      if (this.remote.reloadUrl) {
        return this.remote.reloadUrl
      }
      if (this.remote.reloadResource) {
        return `/${this.remote.path}/admin/reload/${this.remote.reloadResource}`
      }
      return `/${this.remote.path}/admin/reload/${this.resource}`
    },
    tableColumnsForTable () {
      return this.tableColumns.filter((v) => !(v.hiddenTable || v.showPassword))
    },
    tableColumnsForForm () {
      return this.tableColumns.filter((v) => !v.hiddenForm)
    },
    isMobile () {
      return this.$store.getters.isMobile
    },
    isDialogFullscreen () {
      if (this.isMobile) {
        return true
      }
      return this.dialogFullscreen
    }
  },
  watch: {
    dialogVisible (nVal) {
      if (!nVal) {
        this.$refs.ruleForm.resetFields()
      }
    }
  },
  created () {
    // 处理传进来的sort字符串
    if (this.remote.sort) {
      this.sortParams = this.remote.sort.split(',').map((x) => {
        const items = x.split(':')
        return { prop: items[0], order: items.length > 1 ? items[1] : 'ascending' }
      })
    }

    // 调接口取值
    this.fetchData().then(() => {
      const { search } = this.response._links
      if (search) {
        api.get(new URL(search.href).pathname).then((response) => {
          const entry = Object.entries(response._links).filter((e) => e[0] !== 'self')[0]
          if (entry) {
            const data = entry[1]
            // ["http://127.0.0.1:8080/booking/admin/api/games/search/gameIds", "{?", "appId,page,size,sort", "}", ""]
            const hrefSplit = data.href.split(/({\?|})/)
            const params = hrefSplit[2].split(',')
            if (params.length > 3) { // 参数必须包含分页信息才处理
              this.search.pathname = new URL(hrefSplit[0]).pathname
              Object.values(params).forEach((v) => {
                if (v !== 'page' && v !== 'size' && v !== 'sort') {
                  // const val = this.formInitialize[v]
                  // this.search.params[v] = val ?? ''
                  // 搜索条件，默认值为空
                  this.search.params[v] = ''
                }
              })
            }
          }
        })
      }
    })

    if (this.importable || this.createAble || this.updateAble || this.deleteAble) {
      // 功能控制
      api.get(this.profilePath).then((response) => {
        Object.values(response.alps.descriptor).forEach((value) => {
          if (value.id) {
            const verb = value.id.split('-')[0]
            switch (verb) {
              case 'create': this.canCreate = this.createAble && true; break
              case 'update': this.canUpdate = this.updateAble && true; break
              case 'delete': this.canDelete = this.deleteAble && true; break
              default: break
            }
          }
        })
      })
    }
  },

  methods: {
    getFormValuesMethod () {
      return this.formModel
    },
    // 处理input输入不显示。暴力解法
    change () {
      // vue的强刷新
      this.$forceUpdate()
      // 输入时候的change事件，调接口请求数据
      this.handleCurrentChange(1)
    },

    tableColumn (prop) {
      return this.tableColumns.find((v) => v.prop === prop)
    },

    fetchData () {
      // 刚进来是将参数数组清空
      // this.params = [];
      const useSearch = Object.values(this.search.params).find((x) => x)

      const params = []
      if (this.currentPage) {
        params.push(`page=${this.currentPage - 1}`)
      }
      if (this.response.page.size) {
        params.push(`size=${this.response.page.size}`)
      }

      if (this.sortParams) {
        this.sortParams.forEach((x) => {
          params.push(`sort=${x.prop},${x.order.replace('ending', '')}`)
        })
      }

      if (useSearch) {
        Object.keys(this.search.params).forEach((key) => {
          if (this.search.params[key]) {
            const val = encodeURIComponent(`${this.search.params[key]}`)
            params.push(`${key}=${val}`)
          }
        })
      }

      return api.get(`${useSearch ? this.search.pathname : this.createPath}?${params.join('&')}`)
        .then((response) => {
          this.response = response
          this.tableLoading = false
        }).catch(() => {
          this.tableLoading = true
          this.$notify.error({
            title: '错误',
            message: '页面数据加载失败，请刷新页面再试'
          })
        })
    },

    reload () {
      api.get(this.reloadPath).then((res) => {
        const { code, msg } = res
        if (code !== 200) {
          this.$message.error(`重载失败，${code}: ${msg}`)
        } else {
          this.$message.warning('重载成功！')
        }
        this.needReload = false
      })
    },

    handleSizeChange (size) {
      this.response.page.size = size
      this.fetchData()
    },

    handleCurrentChange (number) {
      this.currentPage = number
      this.fetchData()
    },

    dialogFormAdd () {
      this.tableDataIndex = -1
      this.dialogFormReset()
      this.dialogVisible = true
      this.formModel.tableDataIndex = -1
    },

    dialogFormReset () {
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields()
      }
      this.formModel = JSON.parse(JSON.stringify(this.formInitialize))
    },

    dialogFormEdit (row, index) {
      this.dialogVisible = true
      this.tableDataIndex = index
      this.formModel = JSON.parse(JSON.stringify({ ...this.formInitialize, ...row }))
      this.formModel.tableDataIndex = index
      // 处理一下字符串转数组的情况
      this.tableColumns.forEach((column) => {
        // 需要ID属性但row缺少时，从self._links中解析id的值
        if (column.prop === 'id' && !Object.hasOwn(row, 'id')) {
          if (row._links) {
            // const id = row._links.self.href.split('/').pop()
            // row.id = column.type && column.type !== 'number' ? id : Number(id)
            this.formModel.id = row._links.self.href.split('/').pop()
          }
          // console.log('row', row, this.formModel)
        }
        // codec是json类型，string转json
        if (column.codec === 'json') {
          this.formModel[column.prop] = JSON.parse(row[column.prop] || '[]')
        }
        if (column.multiple && this.formModel[column.prop]) {
          const isNumber = column.options[0] && typeof column.options[0].value === 'number'
          this.formModel[column.prop] = this.formModel[column.prop].split(',').map((x) => (isNumber ? Number(x) : x))
        }
      })
      if (this.listeners && this.listeners.beforeFormEdit) {
        this.listeners.beforeFormEdit(this.formModel)
      }
    },

    dialogFormSubmit (destroy) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let promise
          if (destroy) {
            promise = this.$confirm('是否永久删除该数据?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
          } else {
            promise = new Promise((resolve) => {
              resolve()
            })
          }
          promise.then(() => {
            const create = this.tableDataIndex < 0
            let func = create ? api.post : api.put
            if (destroy) {
              func = api.delete
            }
            const pathname = create ? this.createPath : this.changePath
            // form数据处理
            const form = JSON.parse(JSON.stringify(this.formModel))
            for (const column of this.tableColumns) {
              const prop = column.prop
              // 创建时间、修改时间 不处理
              if (prop === 'createdAt' || prop === 'updatedAt') {
                continue
              }
              const formVal = this.formModel[prop]
              if (!formVal) {
                continue
              }
              // codec是json类型，json转string
              if (column.codec === 'json') {
                form[prop] = JSON.stringify(formVal)
              }
              // 处理一下数组转字符串的情况
              if (column.multiple) {
                form[prop] = formVal.join(',')
              }
              if (column.type === 'date') {
                form[prop] = this.$moment(formVal).format(column.format || 'YYYY-MM-DD')
              } else if (column.type === 'datetime') {
                // form[prop] = this.$moment(formVal).format(column.format || 'YYYY-MM-DD HH:mm:ss')
                form[prop] = this.$moment(formVal).format(column.format || 'YYYY-MM-DDTHH:mm:ss.SSSZ')
              }
            }
            // 复制formModel, 提交数据
            if (this.listeners && this.listeners.beforeFormSubmit) {
              this.listeners.beforeFormSubmit(form)
            }
            func(pathname, form).then((response) => {
              this.dialogVisible = false
              this.needReload = true
              if (create || destroy) {
                this.$message.success(create ? '添加成功' : '删除成功')
                this.fetchData()
              } else {
                this.$message.success('修改成功')
                // this.tableData.splice(this.tableDataIndex, 1, response)
                const responseData = this.listeners?.handleFetchData ? this.listeners.handleFetchData([response]) : [response]
                this.tableData.splice(this.tableDataIndex, 1, [...responseData].shift())
              }

              // 回调
              if (this.listeners && this.listeners.afterFormSubmit) {
                this.listeners.afterFormSubmit(this, create, form)
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
          return true
        }
        return false
      })
    },
    customUpdate () {
      this.dialogVisible = false
      this.fetchData()
    },

    // 以文本替换的方式发送一个请求出去，具体看代码实现
    rowOperate (row, index) {
      this.tableDataIndex = index
      const url = this.changePath.replace(this.resources, this.remote.operateUrlReplace)
      this.$notify.info('运行中...')
      api.get(url)
        .then((response) => {
          if (response.code === 0) {
            // 回调
            if (this.listeners && this.listeners.afterOperateSuccess) {
              // 传this，传接口返回值
              this.listeners.afterOperateSuccess(this.$parent, response.result)
            } else {
              this.$notify.success(`成功：${response.result}`)
            }
          } else {
            this.$notify.error({ message: response.message, duration: 0 })
          }
        })
    },

    handleValueCopy (value) {
      navigator.clipboard.writeText(value).then(() => {
        this.$message.success('复制成功')
      })
    },

    clickExtra (row) {
      this.listeners.extra(this.$parent, row)
    },

    writeFile (currentPage, originalData) {
      const json = []
      originalData.forEach((data) => {
        // 这么写是为了导出的时候让ID在第一列
        const row = {
          id: data._links.self.href.split('/').pop()
        }
        Object.assign(row, data)

        // 后端无用的字段
        delete (row.createdAt)
        delete (row.updatedAt)
        delete (row.fallbackType)
        delete (row.fullType)
        delete (row.links)
        delete (row._links)
        json.push(row)
      })
      if (json.length > 0) {
        const header = {}
        Object.keys(json[0]).forEach((x) => {
          const v = this.tableColumn(x)
          header[x] = v ? v.label : ''
        })
        header.id = '填0表示新增'
        json.splice(0, 0, header)
      }

      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.json_to_sheet(json)
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')

      const menus = this.$route.matched.filter((item) => item.path && item.name)
      const filename = `${menus.map((x) => x.name).join('-')}-${currentPage ? '部分' : '全部'}-${new Date().getTime()}.xlsx`
      XLSX.writeFile(workbook, filename)
    },

    // 导出表格相关
    handleDownload (currentPage) {
      if (currentPage) {
        this.writeFile(currentPage, this.tableData)
        return
      }

      this.exportExcelLoading = true

      const useSearch = Object.values(this.search.params).find((x) => x)
      const params = []
      if (useSearch) {
        Object.keys(this.search.params).forEach((key) => {
          if (this.search.params[key]) {
            const val = encodeURIComponent(`${this.search.params[key]}`)
            params.push(`${key}=${val}`)
          }
        })
      }
      params.push('size=1000')

      const path = this.createPath
      const { resources } = this

      const list = [];
      // eslint-disable-next-line func-names
      (async function () {
        let page = 0
        let totalPages = -1
        while (page !== totalPages) {
          // eslint-disable-next-line no-await-in-loop
          // params.push(`page=${page}`)
          const resp = await api.get(`${path}?${params.join('&')}&page=${page}`)
          list.push(...resp._embedded[resources])
          page += 1

          totalPages = resp.page.totalPages
          if (totalPages === -1) {
            break
          }
        }
      }()).then(() => {
        this.writeFile(currentPage, list)
        this.exportExcelLoading = false
      })
    },

    handleImportExcel (force) {
      if (force) {
        // eslint-disable-next-line no-alert
        alert('暂未开放')
        return
      }
      this.importExcelLoading = true
      const file = this.$refs.import.uploadFiles ? this.$refs.import.uploadFiles[0] : null
      if (!file) {
        this.$message.error('你需要先选择文件！')
        return
      }
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file.raw)
      fileReader.onload = (e) => {
        const workbook = XLSX.read(e.target.result)
        if (workbook.SheetNames.length > 0) {
          const sheet = workbook.Sheets[workbook.SheetNames[0]]
          const rows = XLSX.utils.sheet_to_json(sheet, { defval: '' })
          rows.splice(0, 1)

          let countChange = 0
          let countIgnore = 0
          let countFail = 0
          const failMessages = []
          const notify = () => {
            if (countChange + countIgnore + countFail === rows.length) {
              this.importExcelLoading = false
              let info = `修改个数：${countChange}<br>未修改个数：${countIgnore}<br>`
              if (countFail > 0) {
                info += `失败个数：${countFail}<br>失败原因：<br>${failMessages.join('<br>')}<br>`
              }

              if (countChange > 0) {
                info += '<br>由于数据发生改变，页面即将刷新<br>如果确保数据没问题，记得手动点击《重载》按钮'
              }

              this.$alert(info, `导入个数：${rows.length}`, {
                confirmButtonText: '确定',
                dangerouslyUseHTMLString: true,
                callback: () => {
                  if (countChange > 0) {
                    // eslint-disable-next-line no-restricted-globals
                    // location.reload() // 页面刷新会白屏闪一下
                    // this.$router.go(0) // 页面会白屏闪一下
                    this.$router.push({ path: '/empty' })
                  }
                }
              })
            }
          }

          // 控制并发的代码参考：tiny-async-pool
          const executing = new Set()

          rows.forEach(async (value) => {
            const now = new Date().getTime()
            let promise
            if (value.id) {
              promise = api.patch(`${this.createPath}/${value.id}`, value)
            } else {
              promise = api.post(this.createPath, value)
            }
            executing.add(promise)

            promise.then((resp) => {
              executing.delete(promise)
              if (now - new Date(resp.updatedAt).getTime() > 10000) { // 大于10秒，基本就是未修改
                countIgnore += 1
              } else {
                countChange += 1
              }
              notify()
            }).catch(() => {
              executing.delete(promise)
              countFail += 1
              failMessages.push(`&nbsp;&nbsp;&nbsp;&nbsp;第${value.__rowNum__}行，ID=${value.id}`)
              notify()
            })

            if (executing.size > 5) {
              await Promise.race(executing)
            }
          })
          return
        }

        this.$message.error('文件处理异常')
      }
    },

    // 设置字段的排序标识
    handleHeaderCellClassName ({ column }) {
      const param = this.sortParams.find((x) => x.prop === column.property)
      if (param) {
        column.order = param.order
      } else {
        column.order = ''
      }
    },

    handleSortChange ({ prop, order }) {
      const index = this.sortParams.findIndex((x) => x.prop === prop)
      if (index >= 0) {
        if (order) {
          this.sortParams[index].order = order
        } else {
          this.sortParams.splice(index, 1)
        }
      } else if (this.remote.singleSort) {
        this.sortParams = [{ prop, order }]
      } else {
        this.sortParams.push({ prop, order })
      }
      this.fetchData()
    }
  }
}
</script>

<style scoped>
.el-input-group__append {
  background: white;
}
.el-form-item {
  margin-bottom: 18px;
}
.el-form-item__error {
  padding-top: 4px;
}
</style>
